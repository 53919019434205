<template>
  <div class="card border-secondary mb-3 side-margin" style="max-width: 14rem;" :style="show ? notChosenStyle : null">
    <h5  v-if="!isEmpty" class="card-header">{{scanner_name}}</h5>
    <h5  v-if="isEmpty" class="card-header">Wait For Scanner</h5>
    <div v-if="!isEmpty" class="card-body">
      <p class="card-text">{{scanner_description}}</p>
      <!-- <a href="#" class="btn btn-secondary btm-margin">Set Params</a> -->
      <div class="custom-control custom-switch">
        <input v-if="scanner_name != 'ClanTox'" type="checkbox" class="custom-control-input" :id="this.scanner_name" @click="childClick">
        <label class="custom-control-label" :for="this.scanner_name"  >Use this tool</label>
      </div>
    </div>
    <div v-if="isEmpty" style="background-color: #fcfcfc;" class="card-body">
      <img  v-if="isEmpty" style="margin:10%" width="80%" height="80%"  src="../assets/loading_gif.gif" alt="Card image">
    </div>
  </div>
</template>

<script>
export default {
  name: 'scanBox',
    props: {
      scanner_name: {
        type: String,
        required: true,
        default: ""
      },
      scanner_description: {
        type: String,
        required: true,
        default: ""
      },
      scanner_id: {
        type: Number,
        required: true,
        default: -1
      },
  },
  data(){
    return {
        show:false,
        notChosenStyle: {
            background: "#787a7a2e",
        },
      }
  },
    computed: {
    isEmpty: function () {
        if(this.scanner_name == "" && this.scanner_description == "" && this.scanner_id == -1)
        {
          return true;
        }
        else{
          return false;
        }
        
    }},
  methods:
  {
    childClick()
    {
      console.log(this.scanner_name);
      this.$emit("myEvent", this.scanner_name)
      if(this.show)
      {
        this.show = false;
      }
      else{
        this.show = true;
      }
    }

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.side-margin{
  margin: 0% 1%
}
.btm-margin{
    margin-bottom: 3%;
}
.tp-margin{
    margin-top: 3%;
}
.card-body{
  font-size: 0.7rem;
  padding: 1rem !important;
}

</style>
