<template>
<div class="container">
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 tp-margin">
            <h1>CyTox Search</h1>
        </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row tp-margin">
        <div class="col-md-4"></div>
        <div class="col-md-3 bt-margin">
            Select your choice of submition, via a Fasta file or by plain text
        </div>
        <div class="col-md-4">
            <VueToggles id="toggle" :value="file_or_text" @click="update_toggle()" style="background-color:rgb(52 58 64) !important;margin:3% 0%;" />
        </div>
        <div class="col-md-1"></div>
    </div>
    <div class="row tp-margin">
        <div class="col-md-2"></div>
        <div class="col-md-6" v-if="!file_or_text">
            <b-form>
                <b-form-group id="input-group-Sequence" label-cols-sm="3" label-for="Sequence">
                    <b-form-textarea id="sequence" v-model="sequence_to_send" placeholder="Enter Sequence" rows="4" cols="50"></b-form-textarea>
                    <b-form-invalid-feedback>
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-form>
        </div>
        <div class="col-md-6"  v-if="file_or_text">
            <b-form-group label-cols-sm="2" label-size="lg">
                <b-form-file accept=".fasta" v-model="file" ref="file-input" class="mb-2" id="file-large" size="lg"></b-form-file>
            </b-form-group>
        </div>
        <div class="col-md-2"  v-if="!file_or_text">
            <b-button type="submit" variant="dark" style="width:90%;display:block;height:85%" class="" @click="Search">Search</b-button>
        </div>
        <div class="col-md-2"  v-if="file_or_text">
            <div class="row tp-margin">
                <b-button type="submit" variant="dark" style="width:90%;display:block;height:85%" class="" @click="Search">Search</b-button>
            </div>
            <div class="row tp-margin" style="margin-top:10%">
                <b-button @click="file = null"  style="width:90%;display:block;height:85%" >Reset</b-button>
            </div>
        </div>
    </div>
    <div id ='loading-container' class="d-flex justify-content-center" style="margin-top: 2%;">
        <scan-box v-for="(scanner, k) in filteredScanners" :key="k" :scanner_name="scanner.name" :scanner_description="scanner.details" :scanner_id="scanner.id" @myEvent="selectScanner"></scan-box>
    </div>
</div>
</template>

<script>
import VueSimpleAlert from "vue-simple-alert";
import scanBox from "../components/scanBox.vue";
import VueToggles from 'vue-toggles/dist/vue-toggles.ssr';
import 'vue-toggles/dist/vue-toggles.ssr.css';

export default {
    components: {
        scanBox,
        VueToggles,
    },
    data() {
        return {
            scanners_comp: [{scanner_name: "", scanner_description:"", scanner_id:-1},{scanner_name: "", scanner_description:""},
            {scanner_name: "", scanner_description:"", scanner_id:-1}],
            sequence_to_send: "",
            file_or_text:false,
            file:null,
            clantox :"",
            clantox_description:"",
            clantox_id : -1
        };
    },
    computed: {
        filteredScanners() {
            console.log(this.scanners_comp);
            return this.scanners_comp.filter(scanner => scanner.id !== 4);
        }
    },
    methods: {
        formatNames(files) {
            return files.length === 1 ? files[0].name : `${files.length} files selected`
         },
        update_toggle(){
            if(this.file_or_text)
            {
                this.file_or_text = false;
            }
            else{
                this.file_or_text = true;
            }

        },
        selectScanner(specific_scanner_name) {
            if (!this.$store.state.scanners.includes(specific_scanner_name)) {
                this.$store.state.scanners.push(specific_scanner_name);
            } else {
                var index = this.$store.state.scanners.indexOf(specific_scanner_name);
                this.$store.state.scanners.splice(index, 1);
            }
        },
        validateState(param) {
            const {
                $dirty,
                $error
            } = this.$v.form[param];
            return $dirty ? !$error : null;
        },
        async Search() {
            var scanners_chosen = this.$store.state.scanners.toString();
            try {
                var user_name = this.$root.store.username;
                var async_func = null;

                if(this.file_or_text)
                {
                    async_func = new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = async () => {
                            try {
                            // Resolve the promise with the response value
                            return resolve(reader.result);
                            } catch (err) {
                                reject(err);
                            }
                        };
                        reader.onerror = (error) => {
                            reject(error);
                        };
                        reader.readAsText(this.file);
                    });
                }

                if(this.file_or_text)
                {
                    var send_func = this.axios;
                    async_func.then((res) => 
                    {
                        if(scanners_chosen.includes('NNTOX') && !scanners_chosen.includes('SeqScreen')){
                            scanners_chosen = scanners_chosen + ',SeqScreen';  
                        }
                        const content = {
                            username: user_name,
                            sequence: res,
                            scanners: scanners_chosen
                        };
                        const json = JSON.stringify(content);
                        send_func.post(this.$store.state.server_domain + "sequenceSearch", json, this.$store.state.config).then((res) => {
                            if (res.data.data) {
                                VueSimpleAlert.alert(res.data.data);
                                // TODO add here the job to the results table?
                            } else {
                                this.$root.toast("Scanning Failed", "There was a problem with the loading of the sequence", "danger");
                            }
                            this.$store.state.scanners = [];
                            // setTimeout(this.fresh, 3000); //more time or delete?
                            }).catch((err) => {
                                this.$root.toast("Scanning Failed", `${err.response.data.error}`, "danger");
                                this.$store.state.scanners = [];
                                // setTimeout(this.fresh, 3000);
                            });
                        }
                    );
                }
                else
                {
                    if(scanners_chosen.includes('NNTOX') && !scanners_chosen.includes('SeqScreen')){
                        scanners_chosen = scanners_chosen + ',SeqScreen';  
                    }
                    const content = {
                        username: user_name,
                        sequence: this.sequence_to_send,
                        scanners: scanners_chosen
                    };
                    console.log(content);
                    const json = JSON.stringify(content);
                    this.axios.post(this.$store.state.server_domain + "sequenceSearch", json, this.$store.state.config).then((res) => {
                    if (res.data.data) {
                        VueSimpleAlert.alert(res.data.data);
                        // TODO add here the job to the results table?

                    } else {
                        if(res.data.error)
                        {
                            this.$root.toast("Scanning Failed", `${res.data.error}`, "danger");
                        }
                        else{
                            this.$root.toast("Scanning Failed", `${res.data.data}`, "danger");
                        }

                    }
                    // setTimeout(this.fresh, 3000);
                    this.$store.state.scanners = [];

                    }).catch((err) => {
                        this.$root.toast("Scanning Failed", `${err.response.data.error}`, "danger");
                        // setTimeout(this.fresh, 3000);
                        this.$store.state.scanners = [];
                    });
                }
            } catch (err) {
                this.$root.toast("Scanning Failed", `${err.response.data.error}`, "danger");
            }
        },
        fresh() {
            this.$router.go()
        },


        async ScannersComponents() {
            try {

                this.axios.get(this.$store.state.server_domain + "ScannersComponents", this.$store.state.config).then((res) => {
                    if (res.data) {
                        this.scanners_comp = res.data.data;
                        var clantox_obj = this.scanners_comp.pop();
                        this.clantox = clantox_obj.name;
                        this.clantox_description = clantox_obj.details;
                        this.clantox_id = clantox_obj.id;
                    } else {
                        this.$root.toast('Scanners Components',"Scanners Components GET Failed", "danger");
                    }
                }).catch((err) => {
                    if(err.response.data)
                    {
                        this.$root.toast("Scanners Components", `${err.response.data.error}`, "danger");
                    }
                });
            } catch (err) {
                this.$root.toast("Scanners Components", `${err.response.data.error}`, "danger");
            }


        },
        show() {
            this.loadingInstance.show();
        },
        close() {
            this.loadingInstance.close();
        },
    },
    beforeMount() {
    },
    set_empty_comp()
    {
        var temp_comp = []
        for (var x = 0; x < 4; x++) {
        temp_comp[x] = {scanner_name: "", scanner_description:"", scanner_id:-1};
        }
        return temp_comp;
    },
    mounted() {
    this.loadingInstance = this.$veLoading({
        target: document.querySelector("#loading-container"),
        // 等同于
        // target:"#loading-container"
        name: "wave",
    });
    this.scanners_comp= [{scanner_name: "", scanner_description:"", scanner_id:-1},{scanner_name: "", scanner_description:""},
            {scanner_name: "", scanner_description:"", scanner_id:-1}],
    this.show();
    this.ScannersComponents();
    this.close();
    
    },
    destroyed() {
        this.loadingInstance.destroy();
    },
};
</script>

<style>
.btm-margin {
    margin-bottom: 3%;
}

.tp-margin {
    margin-top: 3%;
}
.custom-control
{
    position: absolute !important;
    top: 9.8rem !important;
    left: 4rem !important;
}

.ve-loading-wave-rect{
    background-color: black !important;
}

.custom-control-label{
    padding: 0.2rem !important;
}
</style>
